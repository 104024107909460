.ImageRender {
  position: relative;
  min-height: 100vh;
  width: calc(100% + 32px);
  margin-left: -16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    width: calc(100% + 48px);
    margin-left: -24px;
  }
}

img {
  width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100vh;
}
