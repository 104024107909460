$gold: #FCC73D;
$navy: #0F1D69;
$goldDark: #D3A429;

@font-face {
  font-display: block;
  font-family: 'TungstenCompBook';
  font-weight: 350;
  src: url("../../../assets/fonts/TungstenComp-Book-2.woff");
}

@font-face {
  font-display: block;
  font-family: 'TungstenCompMedium';
  font-style: medium;
  src: url("../../../assets/fonts/TungstenComp-Medium.woff");
}

@font-face {
  font-display: block;
  font-family: 'UnitedSansCdBd';
  font-style: normal;
  font-weight: bold;
  src: url('../../../assets/fonts/UnitedSansCdBd.woff');
}

@font-face {
  font-display: block;
  font-family: 'UnitedSansCdHv';
  src: url('../../../assets/fonts/UnitedSansCdHv.woff');
}


:root {
  --gold: #FCC73D;
  --navy: #0F1D69;
  --gold-dark: #D3A429;
}

.zamboni-racing, .jumbotron-view {
  button {
      display: table;
      margin: 0 auto;
      margin-top: 10%;
      padding: 15px 20px;
      background-color: white;
      color: $navy;
      font-size: 26px;
      line-height: 26px;
      border-radius: 19px;
      font-family:UnitedSansCdBd;
      border: none;
      box-shadow: 1px 6px 6px $goldDark;
      &:active{
        opacity: 0.75;
      }
    }

    hr{
      background: $goldDark;
      border-color: $goldDark;
      background-color: $goldDark;
      border-style: solid;
      box-shadow: none;
      width: 100%;
      border-radius: 30px;
      border-width: 1.5px;
  }


  footer{
    background-color: $navy;
    position: fixed;
    bottom: 0;
    height: 5%;
    z-index: 10;
    width: 100%;

    hr{
      border: none;
    }
  }
}
