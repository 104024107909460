// $blue: #11217a;
$blue: #041E42;
$navy: #0F1D69;
$navyDark: #041e42;
$gold: #FCC73D;
$goldDark: #D3A429;
$goldRich: #ffb81c;
$success: #67ce67;
$error: #ce6767;

@font-face {
  font-display: block;
  font-family: 'TungstenBook';
  font-weight: 350;
  src: url("../../../assets/fonts/Tungsten-Book.woff");
}

@font-face {
  font-display: block;
  font-family: 'TungstenCompBook';
  font-weight: 350;
  src: url("../../../assets/fonts/TungstenComp-Book-2.woff");
}

@font-face {
  font-display: block;
  font-family: 'TungstenMedium';
  font-style: medium;
  src: url("../../../assets/fonts/Tungsten-Medium.woff");
}

@font-face {
  font-display: block;
  font-family: 'TungstenCompMedium';
  font-style: medium;
  src: url("../../../assets/fonts/TungstenComp-Medium.woff");
}

@font-face {
  font-display: block;
  font-family: 'UnitedSansRgBk';
  font-style: normal;
  font-weight: noraml;
  src: url('../../../assets/fonts/UnitedSansRgBk.woff');
}

@font-face {
  font-display: block;
  font-family: 'UnitedSansRgMd';
  font-style: normal;
  font-weight: bold;
  src: url('../../../assets/fonts/UnitedSansRgMd.woff');
}

@font-face {
  font-display: block;
  font-family: 'UnitedSansCdMd';
  font-style: normal;
  font-weight: bold;
  src: url('../../../assets/fonts/UnitedSansCdMd.woff');
}

@font-face {
  font-display: block;
  font-family: 'UnitedSansRgBd';
  font-style: normal;
  font-weight: bold;
  src: url('../../../assets/fonts/UnitedSansRgBd.woff');
}

@font-face {
  font-display: block;
  font-family: 'UnitedSansCdBd';
  font-style: normal;
  font-weight: bold;
  src: url('../../../assets/fonts/UnitedSansCdBd.woff');
}


@font-face {
  font-display: block;
  font-family: 'UnitedSansCdHv';
  src: url('../../../assets/fonts/UnitedSansCdHv.woff');
}


:root {
  --gold: #FCC73D;
  --navy: #0F1D69;
  --gold-dark: #D3A429;
}

$colors: (
  blue: $blue,
  navy: $navy,
  navy-dark: $navyDark,
  gold: $gold,
  gold-dark: $goldDark,
  gold-rich: $goldRich,
  success: $success,
  error: $error,
);

/* Color Options
--------------------------------------------- */
@mixin color-modifiers($attribute: "color", $prefix: "-") {

  @each $name,
  $hex in $colors {
    &#{$prefix}#{"" + $name} {
      #{$attribute}: $hex;
    }
  }
}

.txt {
  @include color-modifiers($attribute: "color", $prefix: "--");
}

.bkg {
  @include color-modifiers($attribute: "background", $prefix: "--");
}

.trivia {
  background-color: $navyDark;
  background-image: url('../../../assets/imgs/trivia-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
  overflow: hidden;

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 82vw;
    min-width: 320px;
    max-width: 98vh;
    margin: auto;
    padding-top: 14.7vh;
  }

  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 120px;
    height: auto;
    transition: all 0.4s ease;
    top: 8.3vh;

    &.minify {
      width: 56.3px;
      top: 5.9vh;
    }
  }

  .cta {
    position: absolute;
    top: 80vh;
    font-family: TungstenMedium;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 2px;
    color: $goldRich;
    margin: 0 auto;
    padding-right: 40px;
    display: inline-block;
    transform: translateX(-50%);
    left: 50%;

    &:after {
      content: '';
      position: absolute;
      width: 30px;
      height: 9px;
      overflow: hidden;
      background: url('../../../assets/imgs/arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;
      right: 0;
      top: 50%;
      transform: translateY(-60%);
    }
  }

  .labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5vh;

    h5 {
      font-family: TungstenMedium;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 2px;
    }
  }

  .progress {
    width: 100%;
    height: 2px;
    background: $blue;

    .bar {
      height: 2px;
      background: $goldRich;
      transition: width 1s linear;
    }
  }
}
