.zamboni-racing .zamboni-team-member-place {
    text-align: center;
    padding-top: 10vh;
    // padding-bottom: 5vh;

    .winning {
      width: 331px;
    }

    .losing {
      > .ordinal-text {
          font-size: 12.25em;
          color: var(--gold-dark);
          font-family: TungstenComp-Medium;
          text-transform: uppercase;
          line-height: 1;
      }

      > .team-zamboni {
          display: inline-block;
          margin-top: -7.5em;
          transform: translateY(-2em);
          width: 37%;
      }

      > .team-name {
          font-weight: bold;
          font-family: UnitedSansCdBd;
          font-size: 1.5em;
          margin-top: -.5em;
          text-transform: uppercase;
      }
    }

}
