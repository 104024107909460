// .MuiAccordionDetails-root {
//   flex-direction: column;

//   fieldset {
//     margin-bottom: 30px;

//     &:last-child {
//       margin-bottom: 0;
//     }
//   }
// }
// .rotate-icon {
//   fill: white;
// }
.settings-button {
  position: absolute;
  left: 1em;
  top: 1em;
  cursor: pointer;
}
.VideoPlayer {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
