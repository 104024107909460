@import './styleguide.scss';

.trivia .competition {
  .labels {
    width: 100%;
    .time {
      font-variant-numeric: tabular-nums;
      text-transform: full-width;
      width: 30px;
    }
  }

  .progress {
    margin-bottom: 4.6vh;

    .bar {
      width: 100%;
    }
  }

  .answer-phase {
    .answer-wrapper {
        &.correct,
        &.incorrect {
          button:not(.last-selected) {
            opacity: 0;
            margin-bottom: -50px;
          }
        }
    }

    button {
      transition: margin 0.25s ease;
    }
  }

  .question {
    font-family: UnitedSansRgBd;
    font-size: 24px;
    line-height: 1;
    font-weight: bold;
    letter-spacing: -0.28px;
    margin-bottom: 4.1vh;
  }

  .answers {
    height: calc(200px + 7.2vh);
    position: relative;
  }

  .answer-wrapper {
    transition: opacity 0.25s ease;

    &.time-out {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.correct,
    &.incorrect {
      .selected + .explanation,
      .last-selected + .explanation {
        opacity: 1;
      }
    }

    &.correct {
      button.selected,
      button.last-selected {
        background: $success;
        border-color: $success;

        &:after {
          border-color: $success;
        }
      }
    }

    &.incorrect {
      button.selected,
      button.last-selected {
        background: $error;
        border-color: $error;

        &:after {
          border-color: $error;
        }
      }
    }

    // &.correct,
    // &.incorrect {
    //   button:not(.selected) {
    //     opacity: 0;
    //     margin-bottom: -50px;
    //   }
    // }

    // &:last-of-type
    // &:last-of-type {
    //   &.correct,
    //   &.incorrect {
    //     button:not(.selected) {
    //       margin-bottom: 0;
    //     }
    //   }
    // }
  }

  button{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: UnitedSansRgBd;
    font-size: 18px;
    letter-spacing: -0.28px;
    white-space: nowrap;
    background: transparent;
    width: 320px;
    height: 50px;
    margin: 0;
    padding: 16px 0;
    border-radius: 25px;
    border: 2px solid $blue;
    background: transparent;
    color: white;
    margin: 0 auto 2.4vh;
    z-index: 1;

    &:active {
      opacity: 0.75;
    }

    &.selected,
    &.last-selected {
      &:after {
        content: '';
        position: absolute;
        width: 330px;
        height: 60px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10025px;
        border-width: 2px;
        border-style: solid;
        border-color: transparent;
      }
    }

    &.selected {
      background: $goldRich;
      color: $navyDark;
      border-color: $goldRich;

      &:after {
        border-color: $goldRich;
      }
    }
  }

  .explanation {
    position: absolute;
    top: calc(50px + 3.7vh);
    opacity: 0;
    pointer-events: none;
    font-family: UnitedSansRgMd;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    z-index: 0;
    transition: all .25s ease;
  }

  .answer-timer {
    position: absolute;
    top: 80vh;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);

    h3 {
      text-align: center;
      font-family: TungstenMedium;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 2px;

      span {
        display: inline-block;
      }

      .time {
        width: 36px;
        margin-left: 10px;
      }
    }
  }
}
