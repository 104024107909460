.zamboni-racing .zamboni-place {
    text-align: center;
    height: 20vh;

    &:first-child {
        margin-top: 5%;
      }

    > .ordinal-text {
        font-size: 5em;
        color: var(--gold-dark);
        font-family: TungstenComp-Medium;
        text-transform: uppercase;
        line-height: 1;
    }

    > .team-zamboni {
        display: inline-block;
        margin-top: -7.5em;
        width: 30%;
    }

    > .team-name {
        font-weight: bold;
        font-family: UnitedSansCdHv;
        font-size: 1.5em;
        text-transform: uppercase;
    }
}
