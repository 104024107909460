@import './styleguide.scss';

.trivia .intro {
  .title {
    font-family: TungstenMedium;
    text-transform: uppercase;
    font-size: 48px;
    line-height: 1.2;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 3.6vh;
    margin-top: 10.9vh;
  }

  .wait {
    position: relative;
    width: 100%;
    margin-bottom: 19vh;

    .progress {
      overflow: hidden;

      .bar {
        width: 100%;
        animation: indeterminateAnimation 1s infinite linear;
        transform-origin: 0% 50%;
      }

      @keyframes indeterminateAnimation {
        0% {
          transform: translateX(0) scaleX(0);
        }

        40% {
          transform: translateX(0) scaleX(0.4);
        }

        100% {
          transform: translateX(100%) scaleX(0.5);
        }
      }
    }
  }

  .introduction {
    font-family: UnitedSansRgBd;
    font-size: 24px;
    line-height: 1;
    letter-spacing: -0.28px;
    margin-bottom: 2.4vh;
  }

  .instructions {
    font-family: UnitedSansRgMd;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.33;
    margin-bottom: 5.3vh;
  }

  .name-form {
    width: 100%;
  }

  .input {
    width: 100%;
    margin-bottom: 5.9vh;
    background: none;
    border: none;
    border-bottom: 3px solid $blue;
    color: white;
    font-family: UnitedSansRgBd;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: -0.28px;
    margin-bottom: 5.9vh;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: white;
    }
  }
}
