@import './styleguide.scss';


.countdown-to-start{
    font-family: UnitedSansCdBd;
    display: flex;
    flex-direction: column;
    padding-top: 15%;
    font-weight: 350;
    animation-name: animIN;
    animation-duration: 1s;


    @keyframes animIN{
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }

    p{
        color: $navy;
        font-size: 18px;
        text-align: center;
    }
    .countdown-content{
        position: fixed;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85vw;
        min-width: 350px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-self: center;
        font-weight: 350;
        margin-top:4%;


        @media screen and (max-height: 855px) {
            .top-content{
                    width: 75vw;
                    margin: 0;
                    padding: 0;
            }
        }
    
        .top-content{
            display: flex;
            flex-direction: row;
            width: 70vw;

            h2{
                color: white;
                font-size: 22px;
                width: 200%;
                margin: 0;
                padding: 0;
                text-align: center;
                line-height: 22px;
            }

            hr{
                transform: translateY(10px);
            }

            @media screen and (max-height: 855px) {

                h2{
                    font-size: 18px;
                }
                hr{
                        // width: 80vw;
                        margin: 0;
                        padding: 0;
                }
            }
        }

        .bottom-content{
            
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-self: center;
            align-items: center;
    
            .LR{
                display: flex;
                flex-direction: column;
                margin: 0;
                padding:0;

                p{
                    margin: 0;
                    padding: 0;
                }
            
                @media screen and (max-height: 855px) {
                    p{
                        font-size: 16px;
                    }
                }

            }

            .center{
                display: flex;
                flex-direction: column;
                height: 50%;
                justify-content: center;
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 12px;
                padding-left: 12px;
                margin-left: 10px;
                margin-right: 8px;
                margin-bottom: 0;
                border: 1.5px solid $navy;
                border-radius: 10px;


                h3{
                    font-weight: bold;
                    font-size: 54px;
                    margin: 0;
                    padding: 0;
                    line-height: 54px;
                    color: white;
                }

                h4{
                    margin: 0;
                    padding: 0;
                    font-size: 15px;
                    transform: translate(0,-10%);
                }

                @media screen and (max-height: 855px) {
                
                    h3{
                        font-size: 40px;
                        line-height: 40px;
                    }

                    h4{
                        font-size: 12px;
                    }
                }
            }

        }
    }
}


.center-content{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    // margin-bottom: 10%;

    hr{
        margin-bottom: 10%;
    }

    img{
        padding: 0;
        margin: 0;
    }

    .zam {
        height: 100%;
        width: auto;
    }

    .swipe{
        height: 100%;
        max-height: 49.7px;
        width: auto;
    }

    h1{
        font-family: TungstenCompBook;
        text-transform: uppercase;
        font-size: 229px;
        font-weight: 350;
        padding: 0;
        margin:0;
        line-height: 229px;
    }

    @media screen and (max-height: 855px) {

        h1{
            font-size: 175px;
            line-height: 150px;
            padding: 0;
            margin:0;
            width: 75vw;
            text-align: center;
        }

        .zam{
            height: 90px;
            width: auto;
        }

        p{
            width: 80vw;
            font-size: 16px;
        }

        hr{
            width: 65vw;
            padding: 0;
            margin: 0;
            margin-bottom: 5%;
        }

        .swipe{
            height: 40px;
            width: auto;
        }
    
    }
}


