@import './styleguide.scss';

.zamboni-racing .post-game {
    text-align: center;
    // position: fixed;
    // z-index: 50;

    .offer {
        margin-top: 1em;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-top: 3%;
        padding: 0;

        img{
            width: 25%;
            padding-left: 2%;
        }
    }
    .score-calculation {
        font-family: TungstenComp-Medium;
        font-size: 9em;
        line-height: 1;
        margin-top: 1em;
    }

    .stats {
      color: #0F1D69;
      font-size: 24px;
      line-height: 1;
      font-family: UnitedSansCdBd;
      text-transform: uppercase;
      margin: 0;
      margin-top: -24px;
      padding-bottom: 10vh;
    }

    .pipe {
      color: $goldDark;
      padding: 0 3px;
    }

    .offerRedeem{
        width: 90vw;
        margin: auto;
        border: 2px dashed $goldDark;
        padding-bottom: 4%;
    }

    button{
        margin-top: 5%;
        padding-right: 15%;
        padding-left: 15%;
    }
}



.redeem{
    position: fixed;
    top:0;
    left: 0;
    color: $navy;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 80;
    background-color: $gold;
    width: 100vw;
    height: 100vh;
    text-align: center;

    transform: translateX(150%) scale(0.5);
    opacity: 0.5;

    .coupon-container{
        border: 1px dashed $goldDark;
        border-width: 2px;
        width: 94vw;
        position: fixed;
        left: 50%;
        top:50%;
        transform: translate(-50%, -50%);
        background-color: $gold;
    }
    h1{
        font-family: TungstenComp-Medium;
        font-size: 180px;
        line-height: 180px;
        margin: 0;
        padding:0;
        font-weight: 350;
        padding-top: 5%;
    }

    h2{
        font-family: UnitedSansCdBd;
        font-size: 28px;
        margin: 0;
        padding:0;
    }

    img{
        width: 234px;
        max-width: 60vw;
    }

    button {
      width: 264px;
    }

    .disclaimer{
        font-family: UnitedSansCdBd;
        font-size: 13px;
        color: $goldDark;
        padding-top: 10%;
        padding: 10% 12px 0 12px;
    }
}
