.zamboni-racing .competition {
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:0;
  margin: 0;
  margin-top: 3%;
  height: 80%;

  .stats {
    width: 100vw;
    max-width: 390px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20%;
  }

  .stat {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-top: 3px;
      margin-left: -20px;
      width: 62px;
    }

    &:nth-child(2) {
      position: relative;
      border-left: 2px solid $goldDark;
      border-right: 2px solid $goldDark;
    }
  }

  h1, h3, h4 {
    margin: 0;
    line-height: 1;
  }

  h1 {
    font-size: 109px;
    font-size: 13vh;
    line-height: 1;
    font-family: TungstenComp-Medium;
    font-weight: 500;
    // margin: 0 0 55px;
    // margin: 0 0 6.5vh;
    margin: 0 0 3.5vh;
  }

  h3 {
    font-family: TungstenComp-Medium;
    font-size: 39px;
  }

  h4 {
    color: white;
    font-family: UnitedSansCdHv;
    font-size: 18px;
    margin-bottom: 5px;
  }

  // .icerink-swipe {
  //   width: 360px;
  //   width: 92.31vw;
  //   max-width: 42.7vh;
  //   background: url('../assets/imgs/icerink-swipe.png');
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   border-radius: 11px;
  //   // margin-bottom: 63px;
  //   // margin-bottom: 8vh;
  //   margin-bottom: 6vh;

  //   .inner {
  //     width: 100%;
  //     padding-bottom: 75%;
  //   }
  // }

  .tap {
    width: 100vw;
    max-width: 40vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 5%;

    &:active {
      .inner {
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
        transform: scale(0.99);
      }
    }

    .inner {
      width: 100%;
      border-radius: 100%;
      transition: all 0.15s ease-in-out;
    }
  }

  .swipe-icon {
    height: 49.7px;
    height: 6vh;
    width: 35.2px;
    width: 5vh;
  }

  .instructions {
    width: 300px;
    text-align: center;
    font-family: UnitedSansCdBd;
    line-height: 1;
    font-size: 22px;
    font-size: 2.6vh;

    span {
      // display: inline-block;
      // margin-bottom: 5px;
      // margin-bottom: 0.6vh;
      white-space: nowrap;

      &.heavy {
        font-weight: 900;
        font-family: UnitedSansCdHv;
      }

      // &.hr {
      //   position: relative;

      //   &::before,
      //   &::after {
      //     content: '';
      //     position: absolute;
      //     height: 2px;
      //     width: 100%;
      //     background: $goldDark;
      //     top: calc(50% - 1px);
      //   }

      //   &::before {
      //     left: calc(-100% - 15px);
      //   }

      //   &::after {
      //     right: calc(-100% - 9px);
      //   }
      // }
    }
  }
}
