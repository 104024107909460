$gold: #FCC73D;
$goldDark: #D3A429;
$navy: hsl(231, 75%, 24%);

@font-face {
  font-display: block;
  font-family: 'TungstenComp-Medium';
  font-style: medium;
  src: url('../assets/fonts/TungstenComp-Medium.woff');
}

@font-face {
  font-display: block;
  font-family: 'UnitedSansCdBd';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/UnitedSansCdBd.woff');
}

@font-face {
  font-display: block;
  font-family: 'UnitedSansCdHv';
  font-style: normal;
  font-weight: heavy;
  src: url('../assets/fonts/UnitedSansCdHv.woff');
}

.zamboni-racing {
  background: $gold;
  color: $navy;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
}
