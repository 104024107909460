@import './styleguide.scss';

.zamboni-racing .Nav {
    z-index: 25;

    .nav-container{
        background-color: $navy;
        display: flex;
        justify-content: center;
    }

    .bluesLogo{
        height: 40px;
        margin: 3% auto 3% auto;
    }

    .ham{
        position: fixed;
        top:3%;
        right:5%
    }
}
