@import './styleguide.scss';

.trivia .leaderboard {
  .congrats,
  .board {
    width: 100%;
  }

  .congrats {
    z-index: 2;

    .progress {
      margin-bottom: 8.2vh;
    }

    .bar {
      background: $success;
    }

    h1 {
      text-align: center;
      text-transform: uppercase;
      color: $success;
      font-family: TungstenMedium;
      font-size: 48px;
      line-height: 1;
      font-weight: 500;
      letter-spacing: 2px;
      margin-bottom: 5vh;
    }

    .details {
      margin-bottom: 16.8vh;
      font-family: UnitedSansRgMd;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: -0.28px;
    }
  }

  .board {
    h1 {
      width: 100%;
      font-family: UnitedSansRgBd;
      font-size: 24px;
      line-height: 1;
      letter-spacing: -0.28px;
      margin-bottom: 2.4vh;
    }
  }

  .records-wrapper {
    position: relative;
    width: 100%;

    // &:after {
    //   content: '';
    //   position: absolute;
    //   width: 100%;
    //   height: 150px;
    //   bottom: 0;
    //   background-image: linear-gradient(to top, #041e42 50%, rgba(4, 30, 66, 0) 100%);
    // }
  }

  .records {
    width: 100%;
    // 5.9vh
    // 44.6px 3.4vh
    // 24px 2.4vh
    // 77px
    // ______
    // 11.7vh
    min-height: 80vh;
    max-height: 80vh;
    overflow: scroll;
    padding-bottom: 50vh;

    /* Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;

    .record {
      margin-bottom: 2.5vh;
    }

    .record.txt--success .bar {
      background: $success;
    }
  }

  .cta {
    z-index: 2;
  }

  .mask {
    position: absolute;
    top: 50vh;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../../../assets/imgs/trivia-background-fade.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 1;
    pointer-events: none;
  }
}
