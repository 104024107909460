.video-outer {
	position: relative;
	width: 680px;
	max-width: 100%;

	.video-inner {
		position: relative;
		height: auto;
		width: 100%;
		height: 0;
		padding-top: #{(52 / 68) * 100%};
    overflow: hidden;

		video,
		.VideoPlayer {
			position: absolute;
			max-width: 100%;
			max-height: 100%;
			min-height: 100%;
			min-width: 100%;
			left: 0;
			top: 0;
			background: black;

      &.rotate {
        position: relative;
        min-width: 0;
        min-height: 0;
        height: 100vw;
        width: 100vh;
        max-height: 100vw;
        max-width: 100vh;
        top: 50%;
        left: 50%;

        &--90 {
          transform: translate(-50%, -50%) rotate(-90deg);
        }
        &--180 {
          transform: translate(-50%, -50%) rotate(-180deg);
        }
        &--270 {
          transform: translate(-50%, -50%) rotate(-270deg);
        }
        &--360 {
          transform: translate(-50%, -50%) rotate(-360deg);
        }
      }
		}

		.controls,
		.light-controls {
			display: block;
			position: absolute;
			bottom: 0;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
		.light-controls {
			bottom: auto;
			top: 0;
		}
	}
}

.StreamWatcher.MuiBox-root, iframe {
	position: absolute;
	height: 100vh;
	width: 100vw;
	max-width: none;
	max-height: none;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	left: 0;
	top: 0;

	.video-inner {
		height: 100%;
		padding-top: 0;
	}
}
