@import '~normalize-scss';

@include normalize();

html {
	box-sizing: border-box;
}

.connected {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	height: auto;
	width: 120px;
}