@font-face {
  font-family: 'PressStart';
  src: url('../assets/PressStart2P-Regular.ttf') format('truetype');
}

.jumbotron-view {
  background: url('../assets/BluesZamboniGame_v3.gif');
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: 700%;
  background-position: top 5% center;

  // &::after {
  //   opacity: 0;
  //   content: '';
  //   position: absolute;
  //   height: 57%;
  //   left: 0;
  //   top: 32%;
  //   width: 50%;
  //   background: url('../assets/Zam Numbers@3x.png');
  //   background-repeat: no-repeat;
  //   background-size: contain;
  // }

  // &.COUNTDOWN_TO_START, &.COMPETITION {
  //   &::after {
  //     opacity: 1;
  //   }
  // }

  // &.intro {

  // }

  h1 {
    text-align: center;
    font-family: "PressStart", sans-serif;
    margin-top: 1em;
    font-size: .65em;
  }

  .intro {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 100vh;

    img {
      width: 100%;
    }
  }

  .jumbo-post-game {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(252, 181, 12);
    overflow: hidden;

    .inner {
      position: relative;
    //   width: 100%;
    //   aspect-ratio: 4 / 3;
    //   background: $gold;
    //   background: rgb(252, 181, 12);
    //   overflow: hidden;
    }

    .title {
      position: absolute;
      font-family: TungstenComp-Medium;
      font-weight: 600;
      letter-spacing: 0.5vw;
      font-size: 10vw;
      line-height: 1;
      margin: 0;
      width: 100%;
      top: 1%;
      text-align: center;
      color: rgb(43, 100, 181);
      text-shadow: 0.075vw 0.125vw white;
    }

    .zamboni-podium {
      height: 100%;
      width: 100%;
      // max-height: none;
    }

    .team-zamboni {
      position: absolute;
      position: absolute;
      width: 26%;
    }

    .place {
      &-1 {
        left: 35%;
        top: 18.5%;
      }
      &-2 {
        left: 3%;
        top: 41.75%;
      }
      &-3 {
        left: 63%;
        top: 56.5%;
      }
    }
  }

  .competition {
    width: 95%;
    aspect-ratio: 4 / 3;
    position: relative;
    padding-left: 2.5%;
    overflow: hidden;

    div.jumbotron-text {
      position: absolute;
      text-align: center;
      width: 100%;
      margin-top: 2.5%;
      font-size: 0.6em;

      @media (min-width:1500px) {
        font-size: 0.8em;
      }

      h1, h2 {
        color: transparent;
        text-align: center;
        font-family: "PressStart", sans-serif;
      }

      h2 {
        font-size: .9em;
        margin-top:2.5%;
      }

      h1.timer {
        margin-bottom: 3.25em;
        font-size: 2em;
      }
    }

    .team {
      position: absolute;
      left: -30%;
      &:before {
        content: '';
        position: absolute;
        bottom: 14%;
        right: 66%;
        height: 21%;
        width: 82.5vw;
        background: url('../assets/Zam_CleanStreak@3x.png');
        background-repeat: no-repeat;
      }
    }

    .team {
      &.team-one {
        top: 31%;
      }

      &.team-two {
        top: 55%;
      }

      &.team-three {
        top: 79%;
      }

      .zambo {
        position: relative;
        width: 27vw;
        z-index: 1;
      }
    }
  }
}
