@import './styleguide.scss';

.zamboni-racing .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    font-family: UnitedSansCdBd;
    font-weight: bold;
    margin: 4% auto 5% auto;
    min-width: 320px;

    img{
        width:60%;
        height: auto;
        padding: 0;
        margin: 0;
    }

    .zams{
        display: flex;
        position: relative;

        .zam1{
            z-index: 10;
        }

        .zam2{
            z-index: 15;
        }

        .zam{
            z-index: 20;
        }
    }

    .animzam{
        animation: driveIn 20s infinite;
        animation-delay: 0s;
        // transform: translate(0, 8%);
        padding-left: 100vw;
    }

    @keyframes driveIn{
        0%{
            transform: translate(-240%, 8%); //red start
        }
        25%{
            transform: translate(-185%, 8%); //red stop
        }
        50%{
            transform: translate(-85%, 8%); //tan stop
        }
        75%{
            transform: translate(15%, 8%); // blue stop
        }
        100%{
            transform: translate(85%, 8%); // blue out
        }
    }

    hr{
        width: 100%;
    }

    h1{
        font-family: TungstenCompBook;
        text-transform: uppercase;
        font-size: 124px;
        line-height: 124px;
        padding: 0;
        padding-top: 1%;
        margin:0;
        font-weight: 350;
    }

    h2{
        padding: 0;
        margin: 0;
        font-size: 28px;
        line-height: 28px;
    }

    .sponsor{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-top: 10%;
        font-size: 10px;

        p{
            padding: 0;
            margin: 0;
        }

        .dobbs{
            width: 20%;
            padding: 0;
            padding-top: 1%;
            margin: 0;
        }
    }
}
